<template>
  <AdminToast/>
  <div class="grid">
    <div class="col-12">
      <Panel>
        <template #header>
          <b>Fiyat Bilgileri</b>
        </template>
        <div class="p-fluid grid">
          <div class="field col-12 md:col-12">
            <label for="resourceId">Metal</label>
            <Dropdown id="resourceId" v-model="dataItem.resourceId" :options="resources" option-label="name" option-value="id" placeholder="Metal Seçin" :filter="true" filterPlaceholder="Metal Ara" />
          </div>
          <div class="field col-12 md:col-12">
            <label for="date">Tarih</label>
            <Datepicker id="date" v-model="dataItem.date" locale="tr" cancelText="Vazgeç" selectText="Seç" nowButton="Şimdi" format="dd.MM.yyy"></Datepicker>

          </div>
          <div class="field col-12 md:col-12">
            <label for="sortOrder">Fiyat</label>
            <InputText id="sortOrder" type="text" v-model="dataItem.price"/>
          </div>
          <div class="field col-12 md:col-12">
            <Button label="Kaydet" class="p-button-success p-button-raised p-button-rounded" @click="save"></Button>
          </div>
        </div>
      </Panel>

    </div>
  </div>
</template>

<script>
import AdminToast from "@/components/shared/toast/adminToast";
import {showErrorMessage} from "@/components/shared/toast/toastFunction";
import ResourceService from "../../services/resourceService";
import ResourceDataService from "../../services/resourceDataService";
import moment from "moment";

export default {
  _service: null,
  _resourceService: null,
  components: {AdminToast},
  created() {
    this._service = new ResourceDataService();
    this._resourceService = new ResourceService();
  },
  mounted() {
    this._resourceService.getAllResources().then(response => {
      this.resources = response.data;
    })
  },
  data() {
    return {
      returnField:"",
      resources:[],
      dataItem: {
        name: "",
        resourceId:"",
        date: moment().toDate(),
        price: 0

      }
    }
  },
  methods: {
    async save() {
      const checkResponse = await this._service.checkPriceExists(this.dataItem.resourceId, this.dataItem.date);



      if(checkResponse.isSuccess)
      {
        console.log(checkResponse);
        if(checkResponse.data.length > 0)
        {
          showErrorMessage(this, "Bu tarih ve bu metal için zaten fiyat girilmiş.");
        }
        else {

          if(this.dataItem.resourceId == 0)
          {
            showErrorMessage(this, "Lütfen Metal Tipini Seçin");
            return;
          }
          await this.insertPrice();
        }
        }

    },
    async insertPrice() {
      const insertResponse = await this._service.insertResourceData(this.dataItem);
      if (insertResponse.isSuccess) {
        await this.$router.push({name: "resourceDataEdit", params: {resourceDataId: insertResponse.data}});
      } else {
        showErrorMessage(this, "Fiyat eklemede sorun ile karşılaşıldı");
      }
    }
  }
}
</script>