import {deleteDataById, getDataBydId, getPaginatedData, insertData, patchData} from "./commonService";

export default class ResourceDataService {

    #defaultUrl = "/api/ResourceData";
    #defaultResourceName = "Fiyat";

    async getAllResourceDatas() {
        return await getPaginatedData(this.#defaultUrl +"/query", this.#defaultResourceName);
    }

    async getResourceDatasByResourceId(resourceId) {
        return await getPaginatedData(this.#defaultUrl + "/query?resourceId=" + resourceId, this.#defaultResourceName);
    }


    async checkPriceExists(resourceId, date) {
        return await getPaginatedData(this.#defaultUrl + "/query?resourceId=" + resourceId + "&priceDate="+date.valueOf(), this.#defaultResourceName);
    }

    async insertResourceData(dataItem) {
        const createData = {
            "resourceId": dataItem.resourceId,
            "date": dataItem.date.valueOf(),
            "price": dataItem.price,
        }


        return await insertData(this.#defaultUrl, createData, this.#defaultResourceName);
    }

    async getResourceDataById(id) {
        return await getDataBydId(this.#defaultUrl, id, this.#defaultResourceName);
    }

    async deleteById(id) {
        return await deleteDataById(this.#defaultUrl, id, this.#defaultResourceName);
    }

    async updateResourceData(id, dataItem) {
        const patchDocument = [
            {"op": "replace", "path": "resourceId", "value": dataItem.resourceId},
            {"op": "replace", "path": "date", "value": dataItem.date.valueOf()},
            {"op": "replace", "path": "price", "value": dataItem.price.valueOf()},
        ];


        return await this.patchResourceData(id, patchDocument);
    }
    async patchResourceData(id, patchDocument) {
        return await patchData(this.#defaultUrl, id, patchDocument, this.#defaultResourceName);
    }
}